import React, {KeyboardEvent, useEffect, useState} from 'react';
import {Link as RouterLink} from "react-router-dom";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import {Button, Slider} from '@mui/material';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';
import RoutingToolLogo from './RoutingToolLogo.png';
import Day from './Day';
import AutoFocusTextField from './AutoFocusTextField';
import Guid from './Guid';
import ImportDay from './ImportDay';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {parseKml, unzipKmz} from "./KmlImporter";
import {
  DragDropContext,
  Draggable,
  DraggableStyle,
  Droppable,
  type DropResult,
  ResponderProvided
} from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Leg from "./Leg";

type HomeProps = {
  days: Day[],
  setDays: (days: Day[]) => void,
  updateDays: (days: Day[]) => Promise<void>,
  addDay: (title: string) => Promise<void>,
  addDayWithData: (day: Day) => Promise<void>,
  addLeg: (dayId: string) => Promise<void>,
  deleteDay: (id: string) => Promise<void>,
  deleteLeg: (dayId: string, legId: string) => Promise<void>,
  editDayTitle: (id: string) => Promise<void>,
  editLegTitle: (dayId: string, legId: string) => Promise<void>,
  updateDayTitle: (id: string, newTitle: string) => Promise<void>,
  updateLegTitle: (dayId: string, legId: string, newTitle: string) => Promise<void>,
  endpoint: string
}

const Home = ({ days, addDay, addDayWithData, setDays, updateDays, addLeg, deleteDay, deleteLeg, editDayTitle, editLegTitle, updateDayTitle, updateLegTitle, endpoint }: HomeProps) => {
  const [confirmDayDeleteData, setConfirmDayDeleteData] = useState<[boolean, string?]>([false, undefined]);
  const [confirmLegDeleteData, setConfirmLegDeleteData] = useState<[boolean, [string?, string?]?]>([false, undefined]);
  const [addDayDialogOpen, setAddDayDialogOpen] = useState(false);
  const [importKmlDialogOpen, setImportKmlDialogOpen] = useState(false);
  const [dayTitleValue, setDayTitleValue] = useState<string>("");
  const [kmlFile, setKmlFile] = useState<File | null>(null);
  const [tolerance, setTolerance] = useState<number>(600);

  useEffect(() => {
    requestData();
  }, []);
  
  const onRefreshButtonClick = async () => {
    await requestData();
  }
  
  const requestData = async () => {
    let response = await fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': 'Basic ' + btoa('appuser:JqmaNfTJz4wapTh38nkqnt5XejcVN5')
      }
    });
    
    let data = await response.json();
    const importedObject = data as ImportDay[];
    const importData = importedObject.map(obj => {
      return {
        id: Guid.newGuid(),
        title: obj.title,
        legs: obj.item.map(i => {
          return {
            id: Guid.newGuid(),
            title: i.title,
            kmlCoordinates: i.kmlCoordinates,
            tolerance: i.tolerance,
            route: i.route.map(r => {
              return {
                id: Guid.newGuid(),
                type: r.type,
                coordinates: {
                  lat: r.lat,
                  lng: r.lng,
                }
              }
            }),
            editMode: false,
            isKml: i.isKml,
          }
        }),
        editMode: false,
      }
    })

    setDays(importData);
  }
  const onSignOutButtonClick = () => {
    localStorage.clear();
    window.location.reload();
  }
  const onExportButtonClick = () => {
    const exportData = days.map(d => {
      return {
        title: d.title,
        day: d.title,
        simulated: false,
        item: d.legs.map(l => {
          return {
            time: "",
            title: l.title,
            titleShort: l.title,
            description: "",
            name: "",
            location: "",
            website: "",
            phone: "",
            mail: "",
            lat: 0,
            lng: 0,
            route: l.route.map(r => {
              return {
                type: r.type,
                lat: r.coordinates.lat,
                lng: r.coordinates.lng,
              }
            })
          }
        })
      }
    })

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(exportData)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "route.json";
    link.click();
  }

  const handleKMZImport = async () => {
    if (kmlFile) {
      const extension = kmlFile.name.split('.').pop();
      switch (extension) {
        case "kmz":
          const kmlContent = await unzipKmz(kmlFile);
          if (kmlContent) {
            const day = parseKml(kmlContent, tolerance);
            await addDayWithData(day);
          }
          break;
        case "kml":
          const content = await kmlFile.text()
          const day = parseKml(content, tolerance);
          await addDayWithData(day);
          break;
        default:
          console.log("Unsupported file uploaded. Skipping import...");
          return;
      }
    }

    setImportKmlDialogOpen(false);
  };

  const handleToleranceChange = (event: Event, newValue: number | number[]) => {
    setTolerance(newValue as number);
  };
  
  const handleKMZFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setKmlFile(e.target.files[0]);
    }
  }

  const handleJsonImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      if (!e.target || !e.target.result) return;
      const importedObject = JSON.parse(e.target.result.toString()) as ImportDay[];
      const importData = importedObject.map(obj => {
        return {
          id: Guid.newGuid(),
          title: obj.title,
          legs: obj.item.map(i => {
            return {
              id: Guid.newGuid(),
              title: i.title,
              kmlCoordinates: i.kmlCoordinates,
              tolerance: i.tolerance,
              route: i.route.map(r => {
                return {
                  id: Guid.newGuid(),
                  type: r.type,
                  coordinates: {
                    lat: r.lat,
                    lng: r.lng,
                  }
                }
              }),
              editMode: false,
              isKml: false
            }
          }),
          editMode: false,
        }
      })

      setDays(importData);
    };
  }

  const handleConfirmDeleteClose = () => {
    setConfirmDayDeleteData([false, undefined]);
    setConfirmLegDeleteData([false, undefined]);
  };

  const handleDayClose = () => {
    setAddDayDialogOpen(false);
    setDayTitleValue("");
  }

  const onAddDay = async () => {
    await addDay(dayTitleValue);
    setDayTitleValue("");
    setAddDayDialogOpen(false);
  }

  const onDeleteDay = async () => {
    if (!confirmDayDeleteData[1]) return;
    await deleteDay(confirmDayDeleteData[1]!);
    setConfirmDayDeleteData([false, undefined]);
  }

  const onDeleteLeg = async () => {
    if (!confirmLegDeleteData[1]) return;
    await deleteLeg(confirmLegDeleteData[1]![0]!, confirmLegDeleteData[1]![1]!);
    setConfirmLegDeleteData([false, undefined]);
  }

  function handleEnterKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === 13) {
      (e.target as HTMLInputElement).blur();
    }
  }

  function handleEnterKeyPressOnDialog(e: KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === 13 && dayTitleValue.length > 0) {
      e.preventDefault();
      onAddDay();
    }
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
    })
  })

  const DraggableComponent = (id: string, index: number) => (props: any) => {
    return (
        <Draggable draggableId={id} index={index}>
          {(provided, snapshot) => (
              <TableRow
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style!)}
                  {...props}
              >
                {props.children}
              </TableRow>
          )}
        </Draggable>
    )
  }

  const DroppableComponent = (
      onDragEnd: (result: DropResult, provided: ResponderProvided) => void) => (props: any) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'1'} direction="vertical">
            {(provided) => {
              return (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                    {props.children}
                    {provided.placeholder}
                  </TableBody>
              )
            }}
          </Droppable>
        </DragDropContext>
    )
  }

  const reorderLeg = (legs: Leg[], startIndex: number, endIndex: number): Leg[] => {
    const result = Array.from(legs);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  
  const moveDayUpwards = async (dayId: string) => {
    const index = days.findIndex(day => day.id === dayId);
    if (index > 0 && index < days.length) {
      await updateDays([
        ...days.slice(0, index - 1),
        days[index],
        days[index - 1],
        ...days.slice(index + 1)
      ] as Day[]);
    }
  }

  const moveDayDownwards = async (dayId: string) => {
    const index = days.findIndex(day => day.id === dayId);
    if (index !== -1 && index < days.length - 1) {
      await updateDays([
        ...days.slice(0, index),
        days[index + 1],
        days[index],
        ...days.slice(index + 2)
      ] as Day[]);
    }
  }

  const updateLegsForDay = async (dayId: string, newLegs: Leg[]) => {
    await updateDays(
        days.map((day) =>
            day.id === dayId
                ? {...day, legs: newLegs}
                : day
        )
    );
  };

  const onDragEnd = async (result: DropResult, dayId: string) => {
    if (!result.destination) {
      return
    }
    
    const day = days.find(day => day.id == dayId);
    if (!day) return;
    const reorderedLegs = reorderLeg(day!.legs, result.source.index, result.destination.index);
    await updateLegsForDay(dayId, reorderedLegs);
  }

  return (
    <Box>
      {/*<img style={{ display: 'block', marginTop: '150px', marginLeft: 'auto', marginRight: 'auto' }} width={400} draggable={false} src={RoutingToolLogo} />*/}
      <Box sx={{ width: '60%', marginLeft: 'auto', marginRight: 'auto', marginTop: '64px', paddingBottom: '128px' }}>
        <Typography sx={{ display: 'inline-block' }} variant="h3">Route</Typography>
        <Box sx={{float: 'right', marginTop: '12px'}}>
          {/*<Button variant="text" startIcon={<AddIcon/>} sx={{marginRight: '8px'}} disableFocusRipple*/}
          {/*        onClick={() => setAddDayDialogOpen(true)}>Add Day</Button>*/}
          {/*<Button variant="text" startIcon={<CloudDownloadIcon />} sx={{ marginRight: '0px' }} disableFocusRipple onClick={onExportButtonClick}>Export</Button>*/}
          <Button variant="text" startIcon={<RefreshIcon/>} sx={{marginRight: '8px'}} disableFocusRipple
                  onClick={onRefreshButtonClick}>Refresh</Button>
          {/*<input*/}
          {/*    accept=".kmz,.kml"*/}
          {/*    style={{display: 'none'}}*/}
          {/*    id="raised-button-file"*/}
          {/*    type="file"*/}
          {/*    onChange={handleKMZImport}*/}
          {/*/>*/}
          {/*<label htmlFor="raised-button-file">*/}
          {/*  <Button */}
          {/*      variant="text" */}
          {/*      component="span" */}
          {/*      startIcon={<CloudUploadIcon/>} */}
          {/*      sx={{marginRight: '8px'}} */}
          {/*      disableFocusRipple>*/}
          {/*    KML/KMZ*/}
          {/*  </Button>*/}
          {/*</label>*/}
          <Button
              variant="text"
              component="span"
              startIcon={<CloudUploadIcon/>}
              sx={{marginRight: '8px'}}
              onClick={() => setImportKmlDialogOpen(true)}
              disableFocusRipple>
            KML/KMZ
          </Button>
          {/*<Button variant="text" startIcon={<LogoutIcon/>} sx={{marginRight: '0px'}} disableFocusRipple*/}
          {/*        onClick={onSignOutButtonClick}>Sign Out</Button>*/}
        </Box>
        <Divider/>

        {days.map((day) => (
            <Box key={day.id} sx={{marginTop: '48px'}}>
              {day.editMode ?
                  <TextField
                      autoFocus
                      margin="none"
                      fullWidth
                      hiddenLabel
                      id="edit-day-title-textfield"
                      defaultValue={day.title}
                      size="small"
                      variant="filled"
                onBlur={async (e) => await updateDayTitle(day.id, e.target.value)}
                onKeyDown={(e) => handleEnterKeyPress(e)}
                InputLabelProps={{ shrink: false }}
                InputProps={{ style: { paddingRight: '24px' }, disableUnderline: true, endAdornment: <InputAdornment position="end"><IconButton onClick={(e) => e.currentTarget.blur()} sx={{ padding: 0, '&:hover': { color: 'primary.main' } }} aria-label="toggle password visibility" edge="end">{<CheckIcon />}</IconButton></InputAdornment> }}
                inputProps={{ style: { fontSize: 24, padding: 0 } }}
              /> :
              <Typography sx={{ display: 'inline-block' }} variant="h5">{day.title}</Typography>
            }

            {day.editMode ? null : <IconButton sx={{ padding: 0, marginLeft: '4px', bottom: '4px', '&:hover': { color: 'primary.main' } }} size="medium" aria-label='edit-day' onClick={async () => await editDayTitle(day.id)} ><EditIcon fontSize='inherit' /></IconButton>}
            {day.editMode ? null : <IconButton sx={{ padding: 0, bottom: '4px', '&:hover': { color: 'error.main' } }} size="medium" aria-label='remove-day' onClick={() => setConfirmDayDeleteData([true, day.id])}><RemoveCircleIcon fontSize='inherit' /></IconButton>}
            {day.editMode ? null : <IconButton sx={{ padding: 0, bottom: '4px', '&:hover': { color: 'primary.main' } }} size="medium" aria-label='move-downwards' onClick={() => moveDayDownwards(day.id)}><ArrowCircleDownIcon fontSize='inherit' /></IconButton>}
            {day.editMode ? null : <IconButton sx={{ padding: 0, bottom: '4px', '&:hover': { color: 'primary.main' } }} size="medium" aria-label='move-upwards' onClick={() => moveDayUpwards(day.id)}><ArrowCircleUpIcon fontSize='inherit' /></IconButton>}

            <TableContainer component={Paper}>
              <Table aria-label="days-table">
                <TableBody component={DroppableComponent((result: DropResult) => onDragEnd(result, day.id))}>
                  {day.legs.map((leg, index) => (
                    <TableRow component={DraggableComponent(leg.id, index)} key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <TableCell sx={{ padding: 0, width: '45px' }}><IconButton aria-label="dragdrop" disableRipple><DragIndicatorIcon /></IconButton></TableCell>
                      <TableCell component="th" scope="row" sx={{paddingLeft: '0'}}>
                        {leg.editMode ?
                          <TextField
                            autoFocus
                            margin="none"
                            fullWidth
                            hiddenLabel
                            id="edit-day-title-textfield"
                            defaultValue={leg.title}
                            size="small"
                            variant="filled"
                            onBlur={async (e) => await updateLegTitle(day.id, leg.id, e.target.value)}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                            InputLabelProps={{ shrink: false }}
                            InputProps={{ style: { paddingRight: '24px' }, disableUnderline: true, endAdornment: <InputAdornment position="end"><IconButton onClick={(e) => e.currentTarget.blur()} sx={{ padding: 0, '&:hover': { color: 'primary.main' } }} aria-label="toggle password visibility" edge="end">{<CheckIcon />}</IconButton></InputAdornment> }}
                            inputProps={{ style: { padding: 0 } }}
                          /> :
                          <div style={{display: 'inline-flex'}}>
                            <Typography variant="body1">{leg.title}</Typography>
                            {leg.isKml ?
                            <Typography variant="caption" sx={{
                              marginLeft: 1, 
                              marginTop: '1px',
                              backgroundColor: 'primary.main', 
                              color: 'white', 
                              paddingLeft: '4px', 
                              paddingRight: '4px',
                              paddingTop: '1px',
                              borderRadius: '4px',
                              display: 'flex',
                              alignSelf: 'flex-start',
                            }}>kml/kmz</Typography> : null}
                          </div>
                        }
                      </TableCell>
                      {leg.editMode ? null : <TableCell sx={{ padding: '2px', width: '34px' }}><IconButton sx={{ '&:hover': { color: 'primary.main' } }} aria-label="edit-route" component={RouterLink} to="/map" state={{ dayId: day.id, leg: leg }} ><MapIcon /></IconButton></TableCell>}
                      {leg.editMode ? null : <TableCell sx={{ padding: '2px', width: '34px' }}><IconButton sx={{ '&:hover': { color: 'primary.main' } }} aria-label="edit-title" onClick={async () => await editLegTitle(day.id, leg.id)}><EditIcon /></IconButton></TableCell>}
                      {leg.editMode ? null : <TableCell sx={{ padding: '2px', width: '34px' }}><IconButton sx={{ '&:hover': { color: 'primary.main' } }} aria-label="delete-row" onClick={() => setConfirmLegDeleteData([true, [day.id, leg.id]])}><DeleteIcon /></IconButton></TableCell>}
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<IconButton sx={{ display: 'flex', margin: 'auto', position: 'relative', bottom: '12px', backgroundColor: '#A8A8A8', padding: 0, color: 'white', '&:hover': { color: 'white', backgroundColor: 'primary.main' } }} onClick={async () => await addLeg(day.id)}><AddCircleIcon /></IconButton>*/}
          </Box>
        ))}
      </Box>

      <Dialog open={importKmlDialogOpen} onClose={() => setImportKmlDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Import KML/KMZ</DialogTitle>
        <DialogContent>
          <TextField
              type="file"
              inputProps={{ accept: '.kml, .kmz' }}
              onChange={handleKMZFileChange}
              fullWidth
              margin="normal"
          />

          {/* Tolerance Slider */}
          {/*<Box mt={3} mb={3}>*/}
          {/*  <Typography gutterBottom>*/}
          {/*    Tolerance ({tolerance})*/}
          {/*  </Typography>*/}
          {/*  <Box display="flex" alignItems="center">*/}
          {/*    <Typography variant="body2" style={{ marginRight: 8 }}>*/}
          {/*      high*/}
          {/*    </Typography>*/}
          {/*    <Slider*/}
          {/*        value={tolerance}*/}
          {/*        min={50}*/}
          {/*        max={2000}*/}
          {/*        step={50}*/}
          {/*        onChange={handleToleranceChange}*/}
          {/*        valueLabelDisplay="auto"*/}
          {/*        aria-labelledby="tolerance-slider"*/}
          {/*    />*/}
          {/*    <Typography variant="body2" style={{ marginLeft: 8 }}>*/}
          {/*      low*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Typography variant="caption" display="block" style={{ marginTop: 8 }}>*/}
          {/*    Attention! Lower tolerance (more waypoints) requires additional API calls, which may lead to higher Mapbox costs. Recommendation is 600.*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <Button onClick={() => setImportKmlDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleKMZImport}>Import</Button>
        </DialogActions>

      </Dialog>


      <Dialog open={addDayDialogOpen} onClose={handleDayClose} fullWidth>
        <DialogTitle>Add New Day</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the title of the day:
          </DialogContentText>
          <AutoFocusTextField
            margin="dense"
            id="day_title"
            placeholder='Title'
            value={dayTitleValue}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: false }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDayTitleValue(e.target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => handleEnterKeyPressOnDialog(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDayClose}>Cancel</Button>
          <Button disabled={dayTitleValue.length < 1} onClick={onAddDay}>Add Day</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDayDeleteData[0]}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Do you really want to delete this day? This process cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={onDeleteDay} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmLegDeleteData[0]}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Do you really want to delete this leg? This process cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={onDeleteLeg} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
