import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import RoutingToolLogo from "./RoutingToolLogo.png";
import {User} from "./App";
import {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";

type SignInProps = {
    user?: User,
    handleSignIn: (user: User) => void,
}

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Canossa APP Racelink '}
            {/*<Link color="inherit" href="https://itconcept.it/">*/}
            {/*    */}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignIn({user, handleSignIn}: SignInProps) {
    
    const [error, setError] = useState<boolean>(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let username = data.get('username') as string;
        const password = btoa(data.get('password') as string);
        if (username === 'demo' && password === 'Nm5zMzI5MXYyZQ==') {
            setError(false);
            handleSignIn({username, password, routeID: -1});    
        }else {
            setError(true);
        }
    };  

    if(user) {
        return <Navigate to="/home" replace />;    
    }

    return null;
    // return (
    //     <Container component="main" maxWidth="xs">
    //         <CssBaseline />
    //         <Box
    //             sx={{
    //                 marginTop: 8,
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             {/*<img style={{display: 'block', marginTop: '150px', marginLeft: 'auto', marginRight: 'auto'}} width={400}*/}
    //             {/*     draggable={false} src={RoutingToolLogo} alt={'logo'}/>*/}
    //             <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
    //                 <TextField
    //                     margin="normal"
    //                     required
    //                     fullWidth
    //                     id="username"
    //                     label="Username"
    //                     name="username"
    //                     autoComplete="username"
    //                     autoFocus
    //                 />
    //                 <TextField
    //                     margin="normal"
    //                     required
    //                     fullWidth
    //                     name="password"
    //                     label="Password"
    //                     type="password"
    //                     id="password"
    //                     autoComplete="current-password"
    //                 />
    //                 {error ?
    //                     <Typography variant="body2" color="error">
    //                         {'The username or password you entered is incorrect.'}
    //                     </Typography>
    //                     : null
    //                 }
    //                 <Button
    //                     type="submit"
    //                     fullWidth
    //                     variant="contained"
    //                     sx={{mt: 3, mb: 2}}
    //                 >
    //                     Sign In
    //                 </Button>
    //             </Box>
    //         </Box>
    //         <Copyright sx={{mt: 8, mb: 4}}/>
    //     </Container>
    // );
}
